// TextUserAvatar.jsx
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Observer from '@researchgate/react-intersection-observer';

import { textColor } from '../style/variables.js';

import AvatarPlaceholder from '../../img/placeholder-avatar-cube-60.svg';

export const TextUserAvatar = ({
  meId = null,
  userId = null,
  username = '',
  avatarColor = '#191919',
  avatarSize = 24,
  fontSize = null,
  fetchUser = () => null,
}) => {
  const [isIntersected, setIsIntersected] = useState(false);
  useEffect(() => {
    if (isIntersected && userId && !username) {
      fetchUser({ userId });
    }
  }, [isIntersected, username, userId, meId, fetchUser]);
  const firstCharacter =
    username?.replace(/^@/, '').slice(0, 1).toUpperCase() || '';
  if (!userId) {
    return (
      <StyledTextUserAvatar
        avatarColor={avatarColor}
        avatarSize={avatarSize}
        fontSize={fontSize ? fontSize : avatarSize * 0.5}
      >
        <img src={AvatarPlaceholder} alt="avatar placeholder" />
      </StyledTextUserAvatar>
    );
  }
  return (
    <Observer
      onChange={({ isIntersecting }, unobserve) => {
        if (isIntersecting) {
          setIsIntersected(isIntersecting);
          unobserve();
        }
      }}
    >
      <StyledTextUserAvatar
        avatarColor={avatarColor}
        avatarSize={avatarSize}
        fontSize={fontSize ? fontSize : avatarSize * 0.5}
      >
        {firstCharacter}
      </StyledTextUserAvatar>
    </Observer>
  );
};

TextUserAvatar.propTypes = {
  username: PropTypes.string,
  userId: PropTypes.string,
  meId: PropTypes.string,
  avatarColor: PropTypes.string,
  avatarSize: PropTypes.number,
  fontSize: PropTypes.number,
  fetchUser: PropTypes.func,
};

const StyledTextUserAvatar = styled.div.attrs(
  ({ avatarSize, fontSize, avatarColor }) => ({
    style: {
      width: `${avatarSize}px`,
      height: `${avatarSize}px`,
      fontSize: `${fontSize}px`,
      backgroundColor: avatarColor,
    },
  })
)`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${textColor.white};
  > img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export default TextUserAvatar;
